<template>
  <div class="content">
    <h1 class="big-title" style="text-align: center;" v-html="$t('welcome-title')"></h1>
    <div class="introduction" style="float:right;">
      <img src="../../public/assets/images/general/Vigie_marine_logo.png" alt="Vigie marine logo"/>
      <div>
        <p v-html="$t('home-p1')"></p>
        <p v-html="$t('home-p2')"></p>
        <p v-html="$t('home-p3')"></p>
        <p v-html="$t('home-p4')"></p>
        <p v-html="$t('home-p5')"></p>
        <p v-html="$t('home-p6')"></p>
      </div>
    </div>
    <div class="outil">
      <b-row>
        <b-col lg="3">
          <b-card-group deck>
            <b-card border-variant="primary">
              <label id="dateTitle" class="typo__label">{{$t("date")}}</label>
              <b-form-group id="dateChoice">
                <b-form-radio-group 
                  v-model="periodControlSwitchValue"
                  @change="changedDate()">
                  <b-form-radio
                    value="specific_date"
                  >{{$t("specific-date")}}</b-form-radio> 
                  <b-form-radio
                    value="range_date"
                  >{{$t("range-date")}}</b-form-radio> 
                </b-form-radio-group>
                <b-form-datepicker
                  id="input-specific-date"
                  v-if='this.periodControlSwitchValue === "specific_date"'
                  v-model="specificDateInput"
                  required
                  class="b-components-size"
                  :locale="$i18n.locale"
                  :min="dateChoixMinimum"
                  :max="dateChoixMaximum"
                ></b-form-datepicker>
                <b-form-datepicker
                  id="input-minrange-date"
                  v-if='this.periodControlSwitchValue === "range_date"'
                  v-model="periodMinInput"
                  required
                  class="b-components-size"
                  :locale="$i18n.locale"
                  :min="dateChoixMinimum"
                  :max="dateChoixMaximum"
                ></b-form-datepicker>
                <b-form-datepicker
                  id="input-maxrange-date"
                  v-if='this.periodControlSwitchValue === "range_date"'
                  v-model="periodMaxInput"
                  required
                  class="b-components-size"
                  :locale="$i18n.locale"
                  :min="dateChoixMinimum"
                  :max="dateChoixMaximum"
                ></b-form-datepicker>
              </b-form-group>
              <label class="typo__label">{{$t("species")}}</label>
              <b-form-group id="espece">
                <multiselect
                  v-model="speciesSelected"
                  :options="speciesOptions"
                  :multiple="true"
                  group-values="libs"
                  :group-label="categorySelection"
                  :group-select="true"
                  :placeholder="$t('select-espece')"
                  track-by="value"
                  :label="nameSelection"
                  :show-labels="false"
                  :searchable="false">
                  <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{$t("selectSpecies")}}</span></template>
                </multiselect>
              </b-form-group>
              <label class="typo__label">{{$t("types-observation")}}</label>
              <b-form-group>
                <multiselect
                  v-model="observationSelected"
                  :options="observationOptions"
                  :multiple="true"
                  group-values="libs"
                  :group-label="categorySelection"
                  :group-select="true"
                  :placeholder="$t('select-type')"
                  track-by="value"
                  :label="nameSelection"
                  :show-labels="false"
                  :searchable="false">
                  <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{$t("selectTypesObservation")}}</span></template>
                </multiselect>
              </b-form-group>
              <label class="typo__label">{{$t("partners")}}</label>
              <b-form-group>
                <multiselect
                  v-model="partenairesSelected"
                  :options="partenairesOptions"
                  :multiple="true"
                  group-values="libs"
                  :group-label="categorySelection"
                  :group-select="true"
                  :placeholder="$t('select-membre')"
                  track-by="value"
                  :label="nameSelection"
                  :show-labels="false"
                  :searchable="false">
                  <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{$t("selectPartenaire")}}</span></template>
                </multiselect>
              </b-form-group>
              <b-button 
                variant="primary"
                @click="sendSearch"
              >
              {{$t("search")}}
            </b-button>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col lg="9">
          <b-card-group deck>
            <b-card border-variant="primary">
              <div id="map" class="home"></div>
            </b-card>
          </b-card-group>
          <b-card-group deck>
              <b-card border-variant="primary">
                <p>{{$t("info-observation-graph1")}} <strong>{{ nb_occurence }}</strong> {{$t("info-observation-graph2")}} <strong>{{ displayMinDate }}</strong> {{$t("info-observation-graph3")}} <strong>{{ displayMaxDate }}</strong></p>
                <b-form-radio-group
                  id="btn-radios-agregat"
                  v-model="agregatgraphic"
                  :options="agregatOptions"
                  :text-field="textSelection"
                  button-variant="outline-info"
                  buttons
                  name="radios-btn-default"
                  @change="updateAgregat()">
                </b-form-radio-group>
                <Chart :data_chart="nb_occurence_date" :dateMinSearch="searchMinDate" :dateMaxSearch="searchMaxDate" :agregatDateChart="agregatgraphic"/>
                <div style="padding: 1.25rem 1.25rem 0rem 1.25rem;">
                  <div class="row" v-for="(color, codecolor) in this.taxoncolors.colorCodes" :key="codecolor">
                    <span class="square" :style="{ 'background-color': color}" style="width: 24px; height: 22px; margin-right: 5px;"></span>
                    <span>{{$i18n.t(codecolor)}}</span>
                  </div>
                </div>
              </b-card>

          </b-card-group>
        </b-col>
    </b-row>
    </div>
    
  </div>
</template>

<script>
import moment from 'moment'
import { loadModules } from 'esri-loader';
import BioFilters from "@/commons/BioFilters.js";
import BioService from "@/commons/BioService.js";
import TaxonColor from "@/commons/TaxonColor.js";
import { Agregrat, Observation, Partenaires, Especes } from "@/constants";

import Chart from '../components/Chart.vue';
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Chart
  },
  data() {
    return {
      specificDateInput: null,
      periodMinInput: null,
      periodMaxInput: null,
      beforeDate: null,
      nowDate: null,
      nb_occurence: 0,
      dateChoixMaximum: null,
      dateChoixMinimum: null,
      displayMinDate: null,
      displayMaxDate: null,
      searchMinDate: null,
      searchMaxDate: null,
      agregatgraphic: 0,
      periodControlSwitchValue: "all",
      speciesSelected: [],
      observationSelected: null,
      partenairesSelected: null,
      taxoncolors: new TaxonColor(),
      serviceBio: new BioService(process.env.VUE_APP_BELUGA_BASE_URL),
      resultRechecherOccurrences: null,
      occurrencemap: null,
      occurrencelayer: null,
      nb_occurence_date: {},
      speciesmarkers: {},
      graphicModule: null,
      squareColors: ["#000000", "#fcfcfc", "#f8f8f8", "#bcbcbc", "#7c7c7c", "#a4e4fc", "#3cbcfc", "#0078f8", "#0000fc", "#b8b8f8", "#6888fc", "#0058f8", "#0000bc", "#d8b8f8", "#9878f8", "#6844fc", "#4428bc", "#f8b8f8", "#f878f8", "#d800cc", "#940084", "#f8a4c0", "#f85898", "#e40058", "#a80020", "#f0d0b0", "#f87858", "#f83800", "#a81000", "#fce0a8", "#fca044", "#e45c10", "#881400"],
    }
  },
  methods: {
    updateAgregat ()
    {
      // selon l'agreagt, refait le graphique
      this.updateOccurrencesGraphic(this.resultRechecherOccurrences);
    },
    loadLastMonth(){

      this.beforeDate = moment().subtract(24, 'hours').subtract(1, 'months').format('YYYY-MM-DD');
      this.nowDate = this.dateChoixMaximum;
      
      this.periodMinInput = this.beforeDate,
      this.periodMaxInput = this.nowDate,
      this.displayMinDate = this.beforeDate,
      this.displayMaxDate = this.nowDate,
      this.periodControlSwitchValue = "range_date",
      this.observationSelected = null,
      this.partenairesSelected = null
    },
    loadAllDictionnaire()
    {
      this.serviceBio.loadDictionnaireTaxons();
      this.serviceBio.loadDictionnaireOrganization();
      this.serviceBio.loadDictionnaireCollection();
    },
    loadMap() {
      loadModules(['esri/Map',
      'esri/views/MapView',
      "esri/Graphic",
      "esri/layers/GraphicsLayer"], {
        css: true
      })
      .then(([ArcGISMap, MapView, Graphic, GraphicsLayer]) => {
        this.graphicModule = Graphic;
        // create map with the given options
        this.taxoncolors.getTaxonCodes().forEach((tcode) => {
          this.speciesmarkers[tcode] = { type: 'simple-marker', color: this.taxoncolors.getTaxonColor(tcode), outline: { color: [255, 255, 255], width: 0.6 }};
        } );

        this.occurrencemap = new ArcGISMap({
          basemap: 'topo-vector',
        });
        // assign map to this view
        this.view = new MapView({
          container: "map",
          map: this.occurrencemap,
          center: [-65, 48],
          zoom: 6,
          popup: {
            dockEnabled: true,
            collapseEnabled: false
          }
        });

        this.occurrencelayer = new GraphicsLayer();
        this.occurrencemap.add(this.occurrencelayer);
      })
      .catch(err => {
        // handle any errors
        console.error(err);
      });
    },
    updateOccurrenceLayer(listOccurrences) {
      // refresh la couche de la carte contenant les observations 
      this.occurrencelayer.removeAll();
      
      listOccurrences.forEach((occurrence_value) => {
        let position = occurrence_value["event"]["location"];
        let orgaName = this.$t('no_member');
        const dateListOccurence = moment(occurrence_value["event"]["dateText"], ['YYYY-MM-DDThh:mm:ssZ']).local().format()
        const datePopup = dateListOccurence.substring(0, 10) + ' ' + dateListOccurence.substring(11, 19) + ' UTC'
        var pointGraphic;
        if(occurrence_value["event"]["organizationId"]){
          orgaName = this.serviceBio.getOrganizationById(occurrence_value["event"]["organizationId"]);
          if(occurrence_value["extradata"]["data"]["nbYoungIndividuals"]) {
            let nbJeune = parseInt(occurrence_value["extradata"]["data"]["nbYoungIndividuals"]);
            const popupTemplate = {
              title: "<b>{Titre}</b>",
              content: "<b>" +  this.$t('species') + "</b> : {NomEspece}</br>" +
                "<b>" +  this.$t('number_of_individuals') + "</b> : {QuantiteIndividus}</br>" +
                "<b>" +  this.$t('number_of_youngIndividuals') + "</b> : {QuantiteYoungIndividus}</br>" +
                "<b>" +  this.$t('member') + "</b> : {NomOrganisation}</br>" + 
                "<b>" +  this.$t('date') + "</b> : {Date}"
            }
            pointGraphic = new this.graphicModule({
              geometry: {
                type: "point",
                longitude: position["longitude"],
                latitude: position["latitude"],
                spatialReference: { wkid: 3857 },
              },
              symbol: this.speciesmarkers[occurrence_value["taxon"]["code"]],
              attributes: {
                Titre: this.$t('observation_member_observer'),
                NomEspece: occurrence_value["taxon"]["name__"],
                QuantiteIndividus: occurrence_value["individualCount"],
                QuantiteYoungIndividus: nbJeune,
                NomOrganisation : orgaName["name__"],
                Date : datePopup,
              },
              popupTemplate: popupTemplate
            });
          }
          else {
            const popupTemplate = {
              title: "<b>{Titre}</b>",
              content: "<b>" +  this.$t('species') + "</b> : {NomEspece}</br>" +
                "<b>" +  this.$t('number_of_individuals') + "</b> : {QuantiteIndividus}</br>" +
                "<b>" +  this.$t('member') + "</b> : {NomOrganisation}</br>" + 
                "<b>" +  this.$t('date') + "</b> : {Date}"
            }
            pointGraphic = new this.graphicModule({
              geometry: {
                type: "point",
                longitude: position["longitude"],
                latitude: position["latitude"],
                spatialReference: { wkid: 3857 },
              },
              symbol: this.speciesmarkers[occurrence_value["taxon"]["code"]],
              attributes: {
                Titre: this.$t('observation_member_observer'),
                NomEspece: occurrence_value["taxon"]["name__"],
                QuantiteIndividus: occurrence_value["individualCount"],
                NomOrganisation : orgaName["name__"],
                Date : datePopup,
              },
              popupTemplate: popupTemplate
            });
          }
        }
        else {
          if(occurrence_value["extradata"]["data"]["nbYoungIndividuals"]) {
            let nbJeune = parseInt(occurrence_value["extradata"]["data"]["nbYoungIndividuals"]);
            const popupTemplate = {
              title: "<b>{Titre}</b>",
              content: "<b>" +  this.$t('species') + "</b> : {NomEspece}</br>" +
                "<b>" +  this.$t('number_of_individuals') + "</b> : {QuantiteIndividus}</br>" +
                "<b>" +  this.$t('number_of_youngIndividuals') + "</b> : {QuantiteYoungIndividus}</br>" +
                "<b>" +  this.$t('member') + "</b> : {NomOrganisation}</br>" + 
                "<b>" +  this.$t('date') + "</b> : {Date}"
            }
            pointGraphic = new this.graphicModule({
              geometry: {
                type: "point",
                longitude: position["longitude"],
                latitude: position["latitude"],
                spatialReference: { wkid: 3857 },
              },
              symbol: this.speciesmarkers[occurrence_value["taxon"]["code"]],
              attributes: {
                Titre: "Observation citoyenne",
                NomEspece: occurrence_value["taxon"]["name__"],
                QuantiteIndividus: occurrence_value["individualCount"],
                QuantiteYoungIndividus: nbJeune,
                NomOrganisation : orgaName,
                Date : datePopup,
              },
              popupTemplate: popupTemplate
            });
          }
          else {
            const popupTemplate = {
              title: "<b>{Titre}</b>",
              content: "<b>" +  this.$t('species') + "</b> : {NomEspece}</br>" +
                "<b>" +  this.$t('number_of_individuals') + "</b> : {QuantiteIndividus}</br>" +
                "<b>" +  this.$t('member') + "</b> : {NomOrganisation}</br>" + 
                "<b>" +  this.$t('date') + "</b> : {Date}"
            }
            pointGraphic = new this.graphicModule({
              geometry: {
                type: "point",
                longitude: position["longitude"],
                latitude: position["latitude"],
                spatialReference: { wkid: 3857 },
              },
              symbol: this.speciesmarkers[occurrence_value["taxon"]["code"]],
              attributes: {
                Titre: "Observation citoyenne",
                NomEspece: occurrence_value["taxon"]["name__"],
                QuantiteIndividus: occurrence_value["individualCount"],
                NomOrganisation : orgaName,
                Date : datePopup,
              },
              popupTemplate: popupTemplate
            });
          }
        }         
        this.occurrencelayer.add(pointGraphic);
      });
    },
    updateOccurrencesGraphic(listOccurrences)
    {
       this.nb_occurence_date = {}
       // affiche les données sur la carte
      // let position;
      listOccurrences.forEach((occurrence_value) => {

      // extrait la date de l'evenement
      // calcul selon l'aggregat de agregatgraphic

      let datesubstring;
      switch(this.agregatgraphic)
      {
        case 0:
          // par jour
          datesubstring = occurrence_value["event"]["dateText"].substring(0,10);
          break;
        case 1:
          // par semaine
          datesubstring = moment(occurrence_value["event"]["dateText"].substring(0,10), "YYYY-MM-DD").startOf('week').format("YYYY-MM-DD");
          break;
        case 2:
          // par mois
          datesubstring = moment(occurrence_value["event"]["dateText"].substring(0,10), "YYYY-MM-DD").startOf('month').format("YYYY-MM-DD");
          break;
      }
      //console.log(datesubstring);
      let occurencetaxon;
      if (!(datesubstring in this.nb_occurence_date))
      {
        // si la date n'est pas disponible dans notre tableau, ajoute une nouveau dictionnaire de valeur par taxon
        occurencetaxon = {};
        this.nb_occurence_date[datesubstring] = occurencetaxon;
      }
      else
      {
        // va chercher pourla date courante, le dictionnaire de nombre d'occurrence par taxon
        occurencetaxon = this.nb_occurence_date[datesubstring];
      }

      let curtaxoncode = occurrence_value["taxon"]["code"];
      //console.log(curtaxoncode);
      if (!(curtaxoncode in occurencetaxon))
      {
        // si le taxon n'est pas connue pour la date courante, ajoute une occurence pour le taxon
        occurencetaxon[curtaxoncode] = 1;
      }
      else
      {
        // ajoute une occuurence pour ce taxon
        occurencetaxon[curtaxoncode] = occurencetaxon[curtaxoncode] + 1;
      }
      })
    },
    processOccureence( occurrence ) {
      if(occurrence.length == 10000) {
          const h = this.$createElement
          let dateStartOccurrence = occurrence[0]["event"]["dateText"]
          let dateEndOccurrence = occurrence[occurrence.length - 1]["event"]["dateText"]
          const vNodesBody = h(
            'p',
            { class: ['text-center', 'mb-0'] },
            [
              h('b-spinner', { props: { type: 'grow', small: true } }),
              ` ${this.$t('preventionSearch1')} `,
              h('strong', dateStartOccurrence),
              ` ${this.$t('preventionSearch2')} `,
              h('strong', dateEndOccurrence),
              ` ${this.$t('preventionSearch3')} `,
              h('b-spinner', { props: { type: 'grow', small: true } })
            ]
          )
          // this.$bvToast.toast(this.$t('preventionSearch', {dateStartOccurrence, dateEndOccurrence}), {
          this.$bvToast.toast([vNodesBody], {
          title: this.$t('warning'),
          variant: 'warning',
          solid: true,
          autoHideDelay: 10000,
        })
      }
      for (var i = occurrence.length - 1; i > -1; i--) {
        var organizationsCode = this.serviceBio.getOrganizationById(occurrence[i]["event"]["organizationId"]);
        if(organizationsCode == undefined) {
          if(occurrence[i]["extradata"]["data"]["certainty"] == 2 
            ||occurrence[i]["lifeStageId"]) {
              occurrence.splice(i, 1);
          }
        }
        else if(organizationsCode["code"] == "ROMM-ADMIN" 
          || occurrence[i]["extradata"]["data"]["certainty"] == 2 
          || occurrence[i]["lifeStageId"]) {
            occurrence.splice(i, 1);
        }
      }

      this.nb_occurence = occurrence.length;
      this.resultRechecherOccurrences = occurrence;
      
      this.updateOccurrenceLayer(occurrence);
      this.updateOccurrencesGraphic(occurrence);
    },
    changedDate() {
      if(this.periodControlSwitchValue === "specific_date") {
        this.periodMinInput = null
        this.periodMaxInput = null
      }
      else if(this.periodControlSwitchValue === "range_date"){
        this.specificDateInput = null
      }
    },
    failedGetOccurrence() {
      this.$store.commit('isSendObservationInProgress', false)
      this.$bvToast.toast(this.$t('errorSearch'), {
          title: this.$t('error'),
          variant: 'danger',
          solid: true
        })
    },
    sendSearch(){
      try {
        // voir quel autre ajouter pour avoir de la données
        this.$store.commit('isSendObservationInProgress', true)
        let bf = new BioFilters();
        if(this.periodControlSwitchValue === "specific_date") {
          bf.startDate = this.specificDateInput + "T00:00:00.000Z";
          bf.endDate = this.specificDateInput + "T23:59:59.000Z";
        }
        else if(this.periodControlSwitchValue === "range_date"){
          bf.startDate = this.periodMinInput + "T00:00:00.000Z";
          bf.endDate = this.periodMaxInput + "T23:59:59.000Z";
        }
        else {
          // Date minimum de 2015 demandé par le ROMM
          bf.startDate ="2015-01-01T00:00:00.000Z";
          // utliser now - 24h
          bf.endDate = "3000-01-01T23:59:59.000Z";
        }
        // Permet de trier par ordre ascendant les observation selon la date
        bf.setOrder("event/eventDateTime");

        if (this.partenairesSelected != null){
          this.partenairesSelected.forEach((partner) => {
            // pour tous les espece selectionnées
            bf.Organizations.push(this.serviceBio.getOrganizationByCode(partner["value"]));
          });
        }

        // console.log(this.speciesSelected)
        if (this.speciesSelected != null){
          if ( this.speciesSelected.length == 0)
          {
            // ajoute l'ensemble des especes
            for (const code of this.taxoncolors.getTaxonCodes()) {
              if (!this.serviceBio.getTaxonByCode(code)) {
                console.log(`Taxon code "${code}" not found in biodiversity taxon list`);
                continue
              }
              bf.Taxons.push(this.serviceBio.getTaxonByCode(code));
            }
          }
          else
          {
            this.speciesSelected.forEach((species) => {
              // pour tous les espece selectionnées
              // console.log(species["value"])
              bf.Taxons.push(this.serviceBio.getTaxonByCode(species["value"]));
            });
          }
        }
        else
        {
          // ajoute l'ensemble des especes
          this.taxoncolors.getTaxonCodes().forEach((code) => {
              bf.Taxons.push(this.serviceBio.getTaxonByCode(code));
            });
        }

        // Ajoute selection de la collection 
        //console.log(this.observationSelected)
        if (this.observationSelected != null){
          
            // pour tous les espece selectionnées
            // console.log(observationSelected["value"])
            //console.log(this.observationSelected["value"]);
            // devrait utiliser le code de la collection dans le dropdown, doit avoir un lien avec le i18n
            this.observationSelected.forEach((obstype) => {
              // pour tous les espece selectionnées
              bf.Collections.push(this.serviceBio.getCollectionByCode(obstype["value"]));
            });
            //bf.Collections.push(this.serviceBio.getCollectionByCode(this.observationSelected["value"]));  
        }
        else
        {
          // Si aucune selection, doit spécifier les 2 collections
          bf.Collections.push(this.serviceBio.getCollectionByCode("ROMM-MM-CROWD"));  
          bf.Collections.push(this.serviceBio.getCollectionByCode("ROMM-MM-OBS"));  
        }


        // console.log(bf)
        if(this.periodControlSwitchValue === "specific_date") {
          this.searchMinDate = this.specificDateInput;
          this.searchMaxDate = this.specificDateInput;
          this.displayMinDate = this.specificDateInput;
          this.displayMaxDate = this.specificDateInput;
        }
        else if(this.periodControlSwitchValue === "range_date"){
          this.searchMinDate = this.periodMinInput,
          this.searchMaxDate = this.periodMaxInput
          this.displayMinDate = this.periodMinInput;
          this.displayMaxDate = this.periodMaxInput;
        }
        
        this.serviceBio.getPaginatedOccurrence(bf, this.processOccureence, this.failedGetOccurrence);
      } catch (error) {
        this.$store.commit('isSendObservationInProgress', false)
        this.$bvToast.toast(this.$t('errorSearch'), {
          title: this.$t('error'),
          variant: 'danger',
          solid: true
        })
      }
      
    }
  },
  computed: {
    agregatOptions() {
      let options = Agregrat;
      return options
    },
    observationOptions() {
      let options = Observation;
      return options
    },
    partenairesOptions() {
      let options = Partenaires;
      return options
    },
    speciesOptions() {
      let options = Especes;
      return options
    },
    nameSelection() {
      return `name${this.$i18n.locale}`;
    },
    textSelection() {
      return `text${this.$i18n.locale}`;
    },
    categorySelection() {
      return `category${this.$i18n.locale}`;
    },
  },
  mounted() {
    // assigne les date min et max
    this.$store.commit('isSendObservationInProgress', true)
    this.dateChoixMinimum = '2015-01-01';
    this.dateChoixMaximum = moment().subtract(24, 'hours').format('YYYY-MM-DD');
    this.loadMap();
    this.loadAllDictionnaire();
    this.loadLastMonth();
    setTimeout(() =>
      this.sendSearch()
    , 3000);
  },
  watch: {
    "$i18n.locale": function () {
      this.loadMap();
      this.sendSearch()
    },
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* esri styles */
  @import url('https://js.arcgis.com/4.4/esri/themes/light/main.css');
  .esri-popup--feature-updated-active {
    opacity: 1 !important;
  }
  .content {
    margin: 0 auto;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1140px;
    width: 100%;
    justify-content: space-evenly
  }

.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduction img {
  height: 180px;
}

  @media screen and (min-width: 768px) {
    .content{
      padding: 1.25rem;
    }
  }

  @media screen and (max-width: 767px){
    .content{
      padding: 0.5rem !important;
    }
  }

  .outil {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #dateChoice > div > div,
  #dateChoice > div > div > div {
    margin-bottom: 0.5rem;
  }

  #nav {
    padding: 30px;
  }
  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }
  #nav a.router-link-exact-active {
    color: #42b983;
  }
  .home {
    width: 100%;
    height: 400px;
  }
</style>
<style>
  #espece .multiselect__option{
    padding-left: 30px;
  }

  #espece .multiselect__option--group{
    padding-left: 12px;
    color: black!important;
    font-weight: bold;
  }

  #btn-radios-agregat {
    display: block;
  }

  .esri-popup--is-docked .esri-popup__header-title, .esri-popup--is-docked .esri-popup__content {
    opacity: 1 !important;
  }
</style>
