const Agregrat = [
  {
    textfr: "Jour",
    texten: "Day",
    value: 0
  },
  {
    textfr: "Semaine",
    texten: "Week",
    value: 1
  },
  {
    textfr: "Mois",
    texten: "Month",
    value: 2
  }
]

const Observation = [
  {
    categoryfr: "Tous les types",
    categoryen: "All types",
    libs: [
      {
        value: 'ROMM-MM-CROWD',
        namefr: "Citoyenne",
        nameen: "Citizen"
      },
      {
        value: 'ROMM-MM-OBS',
        namefr: "Membre observateur",
        nameen: "Observer member"
      }
    ]
  }
]

const Partenaires = [
  {
    categoryfr: "Tous les membres",
    categoryen: "All members",
    libs: [
      {
        value: "ROMM-AUTRE",
        namefr: "Autre membre ( Spécifiez dans la section des remarques )",
        nameen: "Other member ( Specify in remarks section )"
      },
      {
        value: "ROMM-AURORAEXP",
        namefr: "Aurora Expeditions",
        nameen: "Aurora Expeditions"
      },
      {
        value: "ROMM-ALGOMA",
        namefr: "Algoma",
        nameen: "Algoma",
      },
      {
        value: "ROMM-ATLTOW",
        namefr: "Atlantic Towing",
        nameen: "Atlantic Towing"
      },
      {
        value: "ROMM-BAFF",
        namefr: "Baffinland",
        nameen: "Baffinland"
      },
      {
        value: "ROMM-CSL",
        namefr: "Canada Steamship Line",
        nameen: "Canada Steamship Line"
      },
      {
        value: "ROMM-CAPAVE",
        namefr: "Cap Aventure",
        nameen: "Cap Aventure"
      },
      {
        value: "ROMM-LAPOC",
        namefr: "Cégep de La Pocatière",
        nameen: "Cégep de La Pocatière"
      },
      {
        value: "ROMM-BASQ",
        namefr: "Compagnie de navigation des Basques",
        nameen: "Compagnie de navigation des Basques"
      },
      {
        value: "ROMM-CPBSL",
        namefr: "Corporation des pilotes du Bas Saint-Laurent",
        nameen: "Corporation des pilotes du Bas Saint-Laurent"
      },
      {
        value: "ROMM-CROFOR",
        namefr: "Croisière Forillon",
        nameen: "Croisière Forillon",
      },
      {
        value: "ROMM-AML",
        namefr: "Croisières AML",
        nameen: "AML Cruises",
      },
      {
        value: "ROMM-BGASP",
        namefr: "Croisières Baie de Gaspé",
        nameen: "Croisières Baie de Gaspé"
      },
      {
        value: "ROMM-CROI-ESSIP",
        namefr: "Croisières Essipit",
        nameen: "Croisières Essipit"
      },
      {
        value: "ROMM-BJC",
        namefr: "Croisières Julien Cloutier",
        nameen: "Croisières Julien Cloutier"
      },
      {
        value: "ROMM-ESCOUMINS",
        namefr: "Croisière Les Escoumins",
        nameen: "Croisière Les Escoumins"
      },
      {
        value: "ROMM-SEPT",
        namefr: "Croisières Sept-Îles",
        nameen: "Croisières Sept-Îles"
      },
      {
        value: "ROMM-CTMA",
        namefr: "CTMA",
        nameen: "CTMA"
      },
      {
        value: "ROMM-DHM",
        namefr: "Destination Haute-Mer",
        nameen: "Destination Haute-Mer"
      },
      {
        value: "ROMM-ECOMARIS",
        namefr: "Écomaris",
        nameen: "Écomaris"
      },
      {
        value: "ROMM-EDLP",
        namefr: "Excursions de la Providence",
        nameen: "Excursions de la Providence"
      },
      {
        value: "ROMM-GWD",
        namefr: "Excursion G.W.D.",
        nameen: "Excursion G.W.D."
      },
      {
        value: "ROMM-EIAB",
        namefr: "Excursion Île aux Basques (Société Provancher)",
        nameen: "Excursion Île aux Basques (Société Provancher)"
      },
      {
        value: "ROMM-FEDNAV",
        namefr: "Fednav",
        nameen: "Fednav"
      },
      {
        value: "ROMM-GFG",
        namefr: "Gare fluviale Godbout",
        nameen: "Gare fluviale Godbout"
      },
      {
        value: "ROMM-GFSJR",
        namefr: "Gare fluviale St-Joseph-de-la-Rive",
        nameen: "Gare fluviale St-Joseph-de-la-Rive"
      },
      {
        value: "ROMM-GRIAV",
        namefr: "Griffon Aventure",
        nameen: "Griffon Aventure",
      },
      {
        value: "ROMM-GD",
        namefr: "Groupe Desgagnés",
        nameen: "Groupe Desgagnés",
      },
      {
        value: "ROMM-HE",
        namefr: "Hurtigruten Expeditions",
        nameen: "Hurtigruten Expeditions",
      },
      {
        value: "ROMM-ILEGB",
        namefr: "Île Grande Basque (Sept-Îles)",
        nameen: "Île Grande Basque (Sept-Îles)",
      },
      {
        value: "ROMM-M-EM",
        namefr: "M – Expertise Marine",
        nameen: "M – Expertise Marine",
      },
      {
        value: "ROMM-MARATLANTIC",
        namefr: "Marine Atlantic",
        nameen: "Marine Atlantic",
      },
      {
        value: "ROMM-MEME",
        namefr: "Mer et monde Écotours",
        nameen: "Mer et monde Écotours",
      },
      {
        value: "ROMM-NEAS",
        namefr: "NEAS",
        nameen: "NEAS"
      },
      {
        value: "ROMM-NEMOISMER",
        namefr: "Némo ISMER",
        nameen: "Nemo ISMER"
      },
      {
        value: "ROMM-NORAVE",
        namefr: "Noryak Aventures",
        nameen: "Noryak Aventures",
      },
      {
        value: "ROMM-OCEANEX",
        namefr: "Oceanex",
        nameen: "Oceanex"
      },
      {
        value: "ROMM-PC",
        namefr: "Parcs Canada (PC)",
        nameen: "Parcs Canada (PC)"
      },
      {
        value: "ROMM-PCCDBD",
        namefr: "Parcs Canada (Cap-de-bon-désir)",
        nameen: "Parcs Canada (Cap-de-bon-désir)"
      },
      {
        value: "ROMM-REFORMAR",
        namefr: "Reformar",
        nameen: "Reformar"
      },
      {
        value: "ROMM-EMPLOYES",
        namefr: "ROMM Employés",
        nameen: "ROMM Employés"
      },
      {
        value: "ROMM-SAG-AVE",
        namefr: "Saguenay Aventures",
        nameen: "Saguenay Aventures"
      },
      {
        value: "ROMM-SCENIC",
        namefr: "Scenic",
        nameen: "Scenic"
      },
      {
        value: "ROMM-SEABOURN",
        namefr: "Seabourn",
        nameen: "Seabourn"
      },
      {
        value: "ROMM-SEBKA",
        namefr: "Société d'écologie de la batture du Kamouraska",
        nameen: "Société d'écologie de la batture du Kamouraska"
      },
      {
        value: "ROMM-SPM-F",
        namefr: "SPM Ferries",
        nameen: "SPM Ferries"
      },
      {
        value: "ROMM-TIAC",
        namefr: "Traverse de L'Isle-aux-Coudres",
        nameen: "Traverse de L'Isle-aux-Coudres"
      },
      {
        value: "ROMM-TMBCG",
        namefr: "Traverse Matane-Baie-Comeau-Godbout",
        nameen: "Traverse Matane-Baie-Comeau-Godbout"
      },
      {
        value: "ROMM-RDLSS",
        namefr: "Traverse Rivière-du-Loup-Saint-Siméon",
        nameen: "Rivière-du-Loup-St.-Siméon ferry"
      },
      // { value: 'ROMM-ALIB', name: this.$i18n.t('romm-alib') },
      // { value: 'ROMM-AMLRDL', name: this.$i18n.t('romm-amlrdl') },
      // { value: 'ROMM-APA', name: this.$i18n.t('romm-apa') },
      // { value: 'ROMM-AQUA', name: this.$i18n.t('romm-aqua') },
      // { value: 'ROMM-ARBIC', name: this.$i18n.t('romm-arbic') },
      // { value: 'ROMM-ARCHI', name: this.$i18n.t('romm-archi') },
      // { value: 'ROMM-AZIM', name: this.$i18n.t('romm-azim') },
      // { value: "ROMM-BGASP", name: this.$i18n.t("romm-bgasp") },
      // { value: 'ROMM-BPER', name: this.$i18n.t('romm-bper') },
      // { value: 'ROMM-CA', name: this.$i18n.t('romm-ca') },
      // { value: 'ROMM-CAPTR', name: this.$i18n.t('romm-captr') },
      // { value: 'ROMM-CBG', name: this.$i18n.t('romm-cbg') },
      // { value: 'ROMM-CFJOR', name: this.$i18n.t('romm-cfjor') },
      // { value: 'ROMM-CNP', name: this.$i18n.t('romm-cnp') },
      // { value: 'ROMM-CNR', name: this.$i18n.t('romm-cnr') },
      // { value: 'ROMM-CTAA', name: this.$i18n.t('romm-ctaa') },
      // { value: 'ROMM-DAMACHA', name: this.$i18n.t('romm-damacha') },
      // { value: 'ROMM-DECOU', name: this.$i18n.t('romm-decou') },
      // { value: 'ROMM-DUVETNOR', name: this.$i18n.t('romm-duvetnor') },
      // { value: 'ROMM-EC', name: this.$i18n.t('romm-ec') },
      // { value: 'ROMM-ECOM', name: this.$i18n.t('romm-ecom') },
      // { value: 'ROMM-ECUM', name: this.$i18n.t('romm-ecum') },
      // { value: 'ROMM-EFI', name: this.$i18n.t('romm-efi') },
      // { value: 'ROMM-EXNAT', name: this.$i18n.t('romm-exnat') },
      // { value: 'ROMM-EXPLOFJ', name: this.$i18n.t('romm-explofj') },
      // { value: 'ROMM-EXPLOMER', name: this.$i18n.t('romm-explomer') },
      // { value: 'ROMM-ISTOR', name: this.$i18n.t('romm-istor') },
      // { value: 'ROMM-KAYILE', name: this.$i18n.t('romm-kayile') },
      // { value: 'ROMM-LEAUM', name: this.$i18n.t('romm-leaum') },
      // { value: 'ROMM-MARJO', name: this.$i18n.t('romm-marjo') },
      // { value: 'ROMM-MIAC', name: this.$i18n.t('romm-miac') },
      // { value: 'ROMM-NORDIK', name: this.$i18n.t('romm-nordik') },
      // { value: 'ROMM-ORGACT', name: this.$i18n.t('romm-orgact') },
      // { value: 'ROMM-PBIC', name: this.$i18n.t('romm-pbic') },
      // { value: 'ROMM-PNIBRP', name: this.$i18n.t('romm-pnibrp') },
      // { value: 'ROMM-PSAG', name: this.$i18n.t('romm-psag') },
      // { value: 'ROMM-RDLSS', name: this.$i18n.t('romm-rdlss') },
      // { value: 'ROMM-RIVIAIR', name: this.$i18n.t('romm-riviair') },
      // { value: 'ROMM-TILE', name: this.$i18n.t('romm-tile') },
      // { value: 'ROMM-PTURS', name: this.$i18n.t('romm-pturs') },
    ]
  }

]

const Especes = [
  {
    value: null,
    namefr: "Sélectionnez une option...",
    nameen: "Select an option..."
  },
  {
    categoryfr: "Baleines à fanons",
    categoryen: "Baleen whales",
    libs: [
      {
        value: "balmus",
        namefr: "Rorqual bleu",
        nameen: "Blue whale",
      },
      {
        value: "balphy",
        namefr: "Rorqual commun",
        nameen: "Fin whale",
      },
      {
        value: "megnov",
        namefr: "Rorqual à bosse",
        nameen: "Humpback whale",
      },
      {
        value: "balacu",
        namefr: "Petit rorqual",
        nameen: "Minke whale",
      },
      {
        value: "balbor",
        namefr: "Rorqual boréal",
        nameen: "Sei whale",
      },
      {
        value: "escrob",
        namefr: "Baleine grise",
        nameen: "Gray Whale",
      },
      {
        value: "eubgla",
        namefr: "Baleine noire de l'Atlantique Nord",
        nameen: "North atlantic right whale",
      },
      {
        value: "balmys",
        namefr: "Baleine boréale",
        nameen: "Bowhead whale",
      },
      {
        value: "balesp",
        namefr: "Rorqual sp.",
        nameen: "Rorqual sp.",
      },
    ],
  },
  {
    categoryfr: "Baleines à dents",
    categoryen: "Toothed whales",
    libs: [
      {
        value: "delleu",
        namefr: "Béluga",
        nameen: "Beluga whale",
      },
      {
        value: "phopho",
        namefr: "Marsouin commun",
        nameen: "Harbour porpoise",
      },
      {
        value: "phymac",
        namefr: "Cachalot macrocéphale",
        nameen: "Sperm whale",
      },
      {
        value: "orcorc",
        namefr: "Épaulard",
        nameen: "Killer whale",
      },
      {
        value: "glomel",
        namefr: "Globicéphale noir de l'Atlantique",
        nameen: "Long-finned pilot whale",
      },
      {
        value: "hypamp",
        namefr: "Baleine à bec commune",
        nameen: "Northern bottlenose whale",
      },
      {
        value: "monmon",
        namefr: "Narval",
        nameen: "Narwhal",
      },
      {
        value: "lagacu",
        namefr: "Dauphin à flancs blancs",
        nameen: "Atlantic white-sided dolphin",
      },
      {
        value: "lagalb",
        namefr: "Dauphin à nez blanc",
        nameen: "White-beaked dolphin",
      },
      {
        value: "deldel",
        namefr: "Dauphin commun à bec court",
        nameen: "Short-beaked common dolphin",
      },
      {
        value: "delsp",
        namefr: "Dauphin sp.",
        nameen: "Dolphin sp.",
      },
    ],
  },
  {
    categoryfr: "Cétacé sp.",
    categoryen: "Cetacean sp.",
    libs: [
      {
        value: "cetsp",
        namefr: "Cétacé sp.",
        nameen: "Cetacean sp.",
      },
    ],
  },
  {
    categoryfr: "Phoques",
    categoryen: "Seals",
    libs: [
      {
        value: "halgry",
        namefr: "Phoque gris",
        nameen: "Grey seal",
      },
      {
        value: "phovit",
        namefr: "Phoque commun",
        nameen: "Harbor seal",
      },
      {
        value: "phogro",
        namefr: "Phoque du Groenland",
        nameen: "Harp seal",
      },
      {
        value: "cyscri",
        namefr: "Phoque à capuchon",
        nameen: "Hooded seal",
      },
      {
        value: "eribar",
        namefr: "Phoque barbu",
        nameen: "Bearded seal",
      },
      {
        value: "pushis",
        namefr: "Phoque annelé",
        nameen: "Ringed seal",
      },
      {
        value: "phosp",
        namefr: "Phoque sp.",
        nameen: "Seal sp.",
      },
    ],
  },
  {
    categoryfr: "Autres espèces",
    categoryen: "Other species",
    libs: [
      {
        value: "ursmar",
        namefr: "Ours blanc",
        nameen: "Polar bear",
      },
      {
        value: "odoros",
        namefr: "Morse de l'Atlantique",
        nameen: "Walrus",
      },
      {
        value: "dercor",
        namefr: "Tortue luth",
        nameen: "Leatherback turtle",
      },
      {
        value: "cetmax",
        namefr: "Requin pèlerin",
        nameen: "Basking shark",
      },
      {
        value: "molmol",
        namefr: "Poisson-lune",
        nameen: "Sunfish",
      },
      {
        value: "thuthy",
        namefr: "Thon rouge de l’Atlantique",
        nameen: "Atlantic bluefin tuna",
      },
      {
        value: "tessp",
        namefr: "Tortue sp.",
        nameen: "Turtle sp.",
      },
      {
        value: "eussp",
        namefr: "Requin sp.",
        nameen: "Shark sp.",
      },
    ],
  },
]

export { Agregrat, Observation, Partenaires, Especes };