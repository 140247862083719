export default class TaxonColor {
    colorCodes = {}

    constructor() {
        this.colorCodes = {'balmus': '#0D4A70',
        'balphy': '#226E9C',
        'megnov': '#3C93C2',
        'balacu': '#6CB0D6',
        'balbor': '#9EC9E2',
        'escrob': '#4A9DC9',
        'eubgla': '#C5E1EF',
        'balmys': '#E4F1F7',
        'balesp': '#0078f8',
        'delleu': '#8F003B',
        'phopho': '#C40F5B',
        'phymac': '#E32977',
        'orcorc': '#E95694',
        'glomel': '#ED85B0',
        'hypamp': '#F2ACCA',
        'monmon': '#F9D8E6',
        'lagacu': '#FD8D3C',
        'lagalb': '#FEB24C',
        'deldel': '#FED976',
        'delsp': '#FFF3B2',
        'cetsp': '#009ADE',
        'halgry': '#06592A',
        'phovit': '#228B3B',
        'phogro': '#40AD5A',
        'cyscri': '#6CBA7D',
        'eribar': '#9CCEA7',
        'pushis': '#CDE5D2',
        'phosp': '#00CD6C',
        'ursmar': '#DBDBDB',
        'odoros': '#C0C0C0',
        'dercor': '#FFAA00',
        'cetmax': '#000000',
        'molmol': '#808080',
        'thuthy': '#E9002D',
        'tessp': '#7F00FF',
        'eussp': '#FF0000'};
    }

    getTaxonCodes = function()
    {
        return Object.keys(this.colorCodes);
    }

    getTaxonColors = function()
    {
        return Object.values(this.colorCodes);
    }

    getTaxonColor = function( code )
    {
        return this.colorCodes[code];
    }
}