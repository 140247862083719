import i18n from '../i18n'

export default class BioFilters {
    enpand = "";
    startDate = undefined;
    endDate = undefined;
    Taxons = [];
    Institutions = [];
    Collections = [];
    Organizations = [];
    skip = undefined;
    Top = undefined;
    langage = i18n.locale;
    OrderBy = undefined;


    // setter
    setOrder = function( order )
    {
        this.OrderBy = order;
    }


    serializeURL = function SerialiseURL( baseurl )
    {
        var urlparams = baseurl + "?";
        
        // expand, harcoder pour le moment
        urlparams += "$expand=event,+event/location,+event/dateFormat,+extradata,+establishmentMeans";

        // filters
        var filterstr = "";
        //event%2FeventDateTime+ge+datetime%271534-07-14T05%3A00%3A00.000Z%27+and+event%2FeventDateTime+le+datetime%272019-10-08T11%3A39%3A41.000Z
        if ( this.startDate != undefined && this.endDate != undefined)
        {
            if ( filterstr.length > 0)
            {
                filterstr += "+and+";
            }
            else
            {
                filterstr += "&$filter=";
            }
            filterstr += "event/eventDateTime+ge+datetime'" + this.startDate + "'+and+event/eventDateTime+le+datetime'" + this.endDate + "'";
        }
        if ( this.Taxons.length > 0 )
        {
            if ( filterstr.length > 0)
            {
                filterstr += "+and+taxon/id+in+(";
            }
            else
            {
                filterstr += "&$filter=taxon/id+in+(";
            }
            for ( let i = 0; i < this.Taxons.length; ++i)
            {
                if ( i > 0)
                {
                    filterstr += ",";
                }
                filterstr += this.Taxons[i].id;
            }
            filterstr += ")";
        }
        if ( this.Institutions.length > 0 )
        {
            //filterstr += "&$filter=event/dataset/collection/id+in+("
            // pour chaque institution, ajouter l'id
            //filterstr += ")";
        }
        if ( this.Organizations.length > 0 )
        {
            //event/Organization/id+in+(9,14)
            if ( filterstr.length > 0)
            {
                filterstr += "+and+event/organizationId+in+(";
            }
            else
            {
                filterstr += "&$filter=event/organizationId+in+(";
            }
            // pour chaque institution, ajouter l'id
            for ( let i = 0; i < this.Organizations.length; ++i)
            {
                if ( i > 0)
                {
                    filterstr += ",";
                }
                filterstr += this.Organizations[i].id;
            }
            filterstr += ")";
        }
        if ( this.Collections.length > 0 )
        {
            //event/dataset/collection/id+in+(9,14)
            if ( filterstr.length > 0)
            {
                filterstr += "+and+event/dataset/collection/id+in+(";
            }
            else
            {
                filterstr += "&$filter=event/dataset/collection/id+in+(";
            }
            // pour chaque institution, ajouter l'id
            for ( let i = 0; i < this.Collections.length; ++i)
            {
                if ( i > 0)
                {
                    filterstr += ",";
                }
                filterstr += this.Collections[i].id;
            }
            filterstr += ")";
        }
        urlparams += filterstr;

        urlparams += "&$language=" + this.langage;

        // if skip
        if ( this.skip != undefined )
        {
            urlparams += "&$skip=" + this.skip.toFixed(0);
        }
        // if Top
        if ( this.Top != undefined )
        {
            urlparams += "&$top=" + this.Top.toFixed(0);
        }

        // if Orderby
        if ( this.OrderBy != undefined )
        {
            urlparams += "&$orderby=" + this.OrderBy;
        }
        else
        {
            urlparams += "&$orderby=" + "";
        }
        return urlparams;
    }
}