import $ from 'jquery'
import i18n from '../i18n'

export default class BioService {

    mainUrl = 'https://ogsl.ca/beluga/biodiversity/';
    langage = i18n.locale
    listTaxons = {}
    listOrganizations = {}
    listOrganizationsName = {}
    listCollections = {}
    


    constructor(baseUrl) {
        this.mainUrl = baseUrl;
    }


    getPaginatedOccurrence = function GetPaginatedOccurrence( biofilter, processResult, failedgetResult )
    {
        let url = biofilter.serializeURL( this.mainUrl + "occurrenceMeasurements/paginatedOccurrenceMeasurements" );
        $.getJSON( url, processResult).fail( failedgetResult );
    }

    loadDictionnaireTaxons = function LoadDictionnaireTaxons()
    {
        // appel le chargement complet des Taxon et les garde dans un dictionnaire dont la clé est le code
        let url = this.mainUrl + "taxons?$language=" + this.langage;
        let vm = this;
        $.getJSON( url, (data) => {
            data.forEach(function (variable)  { 
                vm.listTaxons[variable["code"]] = variable;
            } );
        });
    }

    loadDictionnaireOrganization = function()
    {
        let url = this.mainUrl + "organizations?$language=" + this.langage;
        let vm = this;
        $.getJSON( url, (data) => {
            data.forEach(function (variable)  { 
                vm.listOrganizations[variable["code"]] = variable;
            } );
        });

        $.getJSON( url, (data) => {
            data.forEach(function (variable)  { 
                vm.listOrganizationsName[variable["id"]] = variable;
            } );
            
        });
    }

    loadDictionnaireCollection = function()
    {
        let url = this.mainUrl + "collections?$language=" + this.langage;
        let vm = this;
        $.getJSON( url, (data) => {
            data.forEach(function (variable)  { 
                vm.listCollections[variable["code"]] = variable;
            } );
        });
    }


    getTaxonByCode = function (codeTaxon)
    {
        return this.listTaxons[codeTaxon];
    }

    getOrganizationByCode = function(CodeOrganization)
    {
        return this.listOrganizations[CodeOrganization];
    }

    getOrganizationById = function(id)
    {
        return this.listOrganizationsName[id];
    }

    getOrganizationName = function()
    {
        let url = this.mainUrl + "organizations?$language=" + this.langage;
        let vm = this;
        $.getJSON( url, (data) => {
            data.forEach(function (variable)  { 
                vm.listOrganizationsName[variable["id"]] = variable;
            } );
        });

        return this.listOrganizationsName;
    }

    getCollectionByCode = function(CodeCollection)
    {
        return this.listCollections[CodeCollection];
    }
}