<template>
  <div>
    <div id="chart" style="width: 100%; height: 520px; overflow-x: scroll"></div>
    <!-- <div id="legend" style="width: 100%; height: 620px;"></div> -->
  </div>
</template>

<script>
import moment from 'moment'
import * as d3 from 'd3';
import TaxonColor from "@/commons/TaxonColor.js";
export default {
  props: ['data_chart', 'dateMinSearch', 'dateMaxSearch', 'agregatDateChart'],
  data () {
    return {}
  },
  mounted () {
    this.initalizeChart();
  },
  methods: {
    initalizeChart: function () {
        this.drawChart();
    },
    refreshChart: function () {
        this.clearCanvas();
        this.drawChart();
    },
    drawChart: function () {
      // Mise a place de l'endroit d'affichage de l'histogramme
      const height = 600;
      
      let margin = { top: 60, bottom: 140, left: 60, right: 60 }

      let listDate = [];
      let curdate = moment(this.dateMinSearch);
      let enddate = moment( this.dateMaxSearch);
      switch(this.agregatDateChart)
      {
        case 0:
          while( curdate <= enddate )
          {
            listDate.push(curdate.format("YYYY-MM-DD"));
            curdate = curdate.add(1,'d');
          }
          break;
        case 1:
          curdate = curdate.startOf('week');
          while( curdate <= enddate )
          {
           listDate.push(curdate.format("YYYY-MM-DD"));
            curdate = curdate.add(1,'w');
          }
          break;
        case 2:
          curdate = curdate.startOf('month');
          while( curdate <= enddate )
          {
            listDate.push(curdate.format("YYYY-MM-DD"));
            curdate = curdate.add(1,'M');
          }
          break;
      }

      // console.log(listDate)

      // Récupération des données
      let date_occurence = Object.entries(this.data_chart).map(
        function(d) {
          return d
        }
      )
      // console.log(date_occurence)

      const nbars = listDate.length;
      const width = (nbars * 22) + margin.left;

      // Quantité des observations selon les jours
      let subgroups = date_occurence.map(function(d) {return d[1]})
      // console.log(subgroups)

      let data = date_occurence.map(v => ({...v[1], a: v[0]}))

      // Liste des taxons trouvé pour la recherche
      let liste_taxon = []
      Object.entries(subgroups).forEach((key) => {
        Object.keys(key[1]).forEach(function eachKey(taxon) {
          if (!liste_taxon.find( taxon2 => taxon2===taxon ))
          {
            liste_taxon.push(taxon)
          }
        });
      })
      // console.log(liste_taxon);

      // Mise en place des observations selon les dates puis selon les taxons
      const stack = d3.stack()
        .keys(liste_taxon)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);

      let series = stack(data); 

      for(var i=0;i<series.length;i++){ 
          for(var j=0;j<series[i].length;j++){
            if(isNaN(series[i][j][1])){
              series[i][j][1] = series[i][j][0];
            }
          }
      }
      // console.log(series); 

      // let listKeySpecies = ["balmus", "balphy", "megnov", "balacu", "balbor", "eubgla", "balmys", "balesp", "delleu", "phopho", "phymac", "orcorc", "glomel", "hypamp", "monmon", "lagacu", "lagalb", "deldel", "delsp", "cetsp", "halgry", "phovit", "phogro", "cyscri", "eribar", "pushis", "phosp", "ursmar", "odoros", "dercor", "cetmax", "molmol", "thuthy"]
      // let listColors = ["#0D4A70","#226E9C","#3C93C2","#6CB0D6","#9EC9E2","#C5E1EF","#E4F1F7","#0078f8","#06592A","#228B3B","#40AD5A","#6CBA7D","#9CCEA7","#CDE5D2","#E1F2E3","#6844fc","#4428bc","#f8b8f8","#00CD6C","#009ADE","#8F003B","#C40F5B","#E32977","#E95694","#ED85B0","#f0d0b0","#FF1F5B","#f83800","#a81000","#fce0a8","#fca044","#e45c10","#881400"]
      // let listSpecies = ["Rorqual bleu", "Rorqual commun", "Rorqual à bosse", "Petit rorqual", "Rorqual boréal", "Baleine noire de l'Atlantique Nord", "Baleine boréale", "Rorqual sp.", "Béluga", "Marsouin commun", "Cachalot macrocéphale", "Épaulard", "Globicéphale noir de l'Atlantique", "Baleine à bec commune", "Narval", "Dauphin à flancs blancs", "Dauphin à nez blanc", "Dauphin commun à bec court", "Dauphin sp.", "Cétacé sp.", "Phoque gris", "Phoque commun", "Phoque du Groenland", "Phoque à capuchon", "Phoque barbu", "Phoque annelé", "Phoque sp.", "Ours blanc", "Morse de l'Atlantique", "Tortue luth", "Requin pèlerin", "Poisson-lune", "Thon rouge de l’Atlantique"]
      // let listSpecies = function() {
      //   if (this.$i18n.locale === 'fr') {
      //     return ["Rorqual bleu", "Rorqual commun", "Rorqual à bosse", "Petit rorqual", "Rorqual boréal", "Baleine noire de l'Atlantique Nord", "Baleine boréale", "Rorqual sp.", "Béluga", "Marsouin commun", "Cachalot macrocéphale", "Épaulard", "Globicéphale noir de l'Atlantique", "Baleine à bec commune", "Narval", "Dauphin à flancs blancs", "Dauphin à nez blanc", "Dauphin commun à bec court", "Dauphin sp.", "Cétacé sp.", "Phoque gris", "Phoque commun", "Phoque du Groenland", "Phoque à capuchon", "Phoque barbu", "Phoque annelé", "Phoque sp.", "Ours blanc", "Morse de l'Atlantique", "Tortue luth", "Requin pèlerin", "Poisson-lune", "Thon rouge de l’Atlantique"]
      //   } else {
      //     return ["Blue whale", "Fin whale", "Humpback whale", "Minke whale", "Sei whale", "North athlantic right whale", "Bowhead whale", "Rorqual sp.", "Beluga whale", "Harbour porpoise", "Sperm whale", "Killer whale", "Long-finned pilot whale", "Northern bottlenose whale", "Narwhal", "Atlantic white-sided dolphin", "White-beaked dolphin", "Dauphin commun à bec court", "Dolphin sp.", "Cetacean sp.", "Grey seal", "Harbor seal", "Harp seal", "Hooded seal", "Bearded seal", "Ringed seal", "Seal sp.", "Polar bear", "Walrus", "Leatherback turtle", "Basking shark", "Sunfish", "Atlantic bluefin tuna"]
      //   }
      // }
      
      let tcolors = new TaxonColor();
      // Définissions des couleurs selon les groupes possibles
      var color = d3.scaleOrdinal()
        .domain(tcolors.getTaxonCodes())
        .range(tcolors.getTaxonColors())

      // Set axe des X
      var x = d3.scaleBand()
        .domain(listDate)
        .range([0, width - margin.right])
      let xAxis = d3.axisBottom(x)

      // Set axe des Y
      var y = d3.scaleLinear()
        .domain([0, d3.max(series, d => d3.max(d, d => d[1]))])
        .range([height - margin.top - margin.bottom, 0]);
      let yAxis = d3.axisLeft(y)
        
      // Ajout du graph dans la div + configuration de ses dimensions
      let svg = d3
        .select("#chart")
        .append("svg")
        .attr("width", width)
        .attr("height", 500)
        .append('g')
        .attr('transform', 'translate(' + margin.left/2 + ',' + margin.top/2 + ')')


      var chart = svg.append('g').attr('id','chartBar');

      var div = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("display", "none");

      // Ajout de l'axe des X sur le graph + texte
      chart.append('g')
        .classed("x axis", true)
        .attr('transform', 'translate(' + 0 + ',' + (height - margin.top - margin.bottom) + ')')
        .call(xAxis)
        .selectAll("text") 
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-65)"); 

      // Ajout de l'axe des Y sur le graph
      chart.append('g')
        .classed("y axis",true)
        .attr('transform', 'translate(0, 0)')
        .call(yAxis)

      // Ajout des données dans le graph avec les couleurs
      chart.append("g")
        .selectAll("g")
        .data(series)
        .join("g")
          .attr("fill", function(d) { return color(d.key); })
          .attr("class", function(d){ return "myRect " + d.key })
        .selectAll("rect")
        .data(d => d.map(v => ({...v, key: d.key})))
        .join("rect")
          .attr("x", (d) => x(d.data.a) + 1)
          .attr("width", x.bandwidth() - 2)
          .attr("y", d => y(d[1]))
          .attr("height", d => y(d[0]) - y(d[1]))
          .classed('observation', true)
          .style("opacity", 1)
          .on("mouseover", (event,d) => {
            d3.select(event.target)
              .style("stroke", "black")
              .style("opacity", 1)
            div.transition()
              .duration(200)
              .style("opacity", 1)
              .style("display", null)
            div.html(
              this.$t(d.key) + ": " + (d[1] - d[0]) + " observation(s)")
              .style("left", (event.pageX - 50) + "px")
              .style("top", (event.pageY - 30) + "px");
            })
          .on('mouseout', function(){
            div.transition()
              .duration(200)
              .style("opacity", 0)
              .style("display", "none");
              
            d3.select(this)
            .style("stroke", "none")
            .style("opacity", 1)
        })

      // // Ajout de la légend
      // var legend = d3
      //   .select("#legend")
      //   .append("svg")
      //   .attr("width", width)
      //   .attr("height", 600)
      //   .attr('class', 'legend')
      //   .attr("transform", 'translate(' + 0 +"," + (10) + ')');

      // // let legW = 250;

      // // Configuration de la légend selon les taxons et ses dimensions
      // legend.selectAll('rect')
      //   .data(listColors)
      //   .enter()
      //   .append('rect')
      //   .attr('x', 0)
      //   .attr('y', function(d, i){
      //       return i * 18;
      //   })
      //   .attr('width', 12)
      //   .attr('height', 16)
      //   .attr("fill", function(d) { return d; })
      
      // legend.selectAll('text')
      //   // .data(listSpecies())
      //   .data(listSpecies)
      //   .enter()
      //   .append('text')
      //   .text(function(d){
      //       return d;
      //   })
      //   .attr('x', 18)
      //   .attr('y', function(d, i){
      //       return i * 18;
      //   })
      //   .attr('text-anchor', 'start')
      //   .attr('alignment-baseline', 'hanging');
    },
    clearCanvas: function () {
        d3.select("#chart").selectAll("*").remove();
        d3.select("#legend").selectAll("*").remove();
    },
    getHeight: function () {
        return ("#chart").height || 200;
    },
    getWidth: function () {
        return ("#chart").width || 200;
    }
  },
  watch: { // <-- watch functions
    data_chart: function() { // watch it
      this.refreshChart();
      this.$store.commit('isSendObservationInProgress', false)
    }
  }
}
</script>
<style>
.selection {
  fill: none;
}

div.tooltip {
  position: absolute;
  text-align: center;
  padding: 5px;
  font: 12px sans-serif;
  background: white;
  border: solid 1px;
  border-radius: 5px;
  pointer-events: none;
}

</style>
<style scoped>

</style>