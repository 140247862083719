<template>
  <div>
    <b-overlay :show="isSendObservationInProgress" rounded="sm">
      <div id="app">
        <Header></Header>
        <span v-if='this.showTest === "true"' style="color: black; background-color: red; font-weight: bold; text-align: center;">Site en développement - Version test : 2022-09-20</span>
        <b-button v-if='this.showTest === "true"' href="/changelog" style="font-weight: bold; text-align: center;">Voir les modifications implémentées</b-button>
        <router-view/>
        <Footer></Footer>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Header from '@/views/Header.vue'
import Footer from '@/views/Footer.vue'
require('../public/assets/custom-fonts/stylesheet.css')
export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer
  },
  data () {
    return {
      showTest: process.env.VUE_APP_SHOW_TEST,
    }
  },
  computed: {
    isSendObservationInProgress () {
      return this.$store.getters.isSendObservationInProgress
    }
  },
}
</script>

<style>
h1 {
    font-size: 30pt!important;
    font-family: 'geomanistregular';
    margin-top: 0.8em!important;
    margin-bottom: 8px!important;
}

h2 {
    font-size: 22pt;
    font-family: 'Muli';
}

h3 {
    font-size: 16pt;
    font-family: 'Muli';
    font-weight: bold;
}

p {
    font-size:13pt;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  /* Le rendering de Muli n'est pas le même que sur le site navigationbaleine. Il est aussi en gras par défaut. Jamais trouvé pourquoi.*/
  /* font-family: "Muli", Sans-serif; */
}
.toUppercase {
  text-transform: uppercase;
}

a:hover {
  color: #9BA8B0!important
}
</style>
