<template>
  <div class="content">
    <div>
      <strong>## 2022-09-20</strong>
      <p>
        - Ajout du partenaire Croisière Baie de Gaspé<br/>
        - Retrait du partenaire Croisière Julien Cloutier  
      </p>
      <strong>## 2022-09-01</strong>
      <p>
        - Ajout du partenaire Cap Aventures
      </p>
      <strong>## 2022-08-29</strong>
      <p>
        - Optimisation des données
      </p>
      <strong>## 2022-07-12</strong>
      <p>
        - Ajout du partenaire Atlantic Towing
      </p>
      <strong>## 2022-06-21</strong>
      <p>
        - Ajout du partenaire Saguenay Aventures
      </p>
      <strong>## 2022-06-08</strong>
      <p>
        - Ajout de 2 partenaires Parcs Canada (PC) et Parcs Canada (Cap-de-bon-désir)
      </p>
      <strong>## 2022-05-24</strong>
      <p>
        - Mise à jour des packages
      </p>
      <strong>## 2022-03-28</strong>
      <p>
        - Ajout du portail pêcheur dans le footer<br/>
        - Mise à jour des packages
      </p>
      <strong>## 2021-09-28</strong>
      <p>
        - Fix nom Requin sp.
      </p>
      <strong>## 2021-09-16</strong>
      <p>
        - Ajout de deux espèces Tortue sp. et Requin spp.
      </p>
      <strong>## Version 2021-05-14</strong>
       <p>
      - Modification des couleurse<br>
      - Meilleure gestion d'erreure
      </p>
      <strong>## Version 2021-05-14</strong>
      <p>
      - Ajout Google Analytic<br>
      - Modification des texte de sélections<br>
      - Ajout du spinner lors de la recherche
      </p>
        <strong>## Version 2021-05-13</strong>
        <p>
        - Agrégat selon les jours, semaines et mois sur le graphique<br>
        - Ajout des textes d'introduction<br>
        - Modification du layout principal<br>
        </p>
        <strong>## Version 2021-05-10</strong>
        <p>
        - Dates minimum et maximum sur les calendriers<br>
        - Meilleure configuration des liens vers les service externe en configuration<br>
        </p>
        <strong>## version 2021-03-11</strong>
        <p>
          - Début graphique selon la recherche<br>
          - Ajout du filtre de recherche patenaire<br>
          - Ajout de la legend<br>
          - Ajout du tooltip sur le graphique<br>
          - Prérecherche du dernier mois au chargement de la page<br>
          - Ajout des couleurs des espèces<br>
          - Ajout de la traduction<br>
        </p>
        <strong>## version 2021-01-25</strong>
        <p>
          - Recherche avec date<br>
          - Ajout de point sur la map<br>
          - Ajout des filtres de Taxons et dictionnaire de correspondance<br>
          - Ajout des taxons dans la recherche
        </p>
    </div>
    <div>
        <strong>## version 2021-01-22</strong>
        <p>
          - Debut support acces au données de Biodiversité<br>
          - Ajout de Jquery<br>
          - Implémentation du bouton de recherche<br>
          - Support pour filtre par Collections
        </p>
    </div>
    <div>
        <strong>## version 2021-01-21</strong>
        <p>
          - Ajout D3.js<br>
          - Ajout du graphique<br>
          - Ajout de bar pour les données dans le graphique<br>
          - Ajout du graphique responsive<br>
          - Test tooltip sur le graphique<br>
          - Désactivation du graphique
        </p>
    </div>
    <div>
        <strong>## version 2021-01-11</strong>
        <p>
          - Ajout des espèces<br>
          - Modification des emplacements des assets<br>
          - Optimisation
        </p>
    </div>
    <div>
        <strong>## version 2021-01-06</strong>
        <p>
          - Ajout section map et section parametre<br>
          - Ajout date
        </p>
    </div>
    <div>
        <strong>## version 2020-12-17</strong>
        <p>
          - Initialisation du projet<br>
          - Ajout des fichiers d'environnement<br>
          - Ajout dockerisation<br>
          - Ajout du CI Gitlab<br>
          - Ajout du plugin Vue-Router<br>
          - Ajout de la page Changelog<br>
          - Ajout du Header<br>
          - Ajout du Footer<br>
          - Ajout du Home<br>
          - Ajout du message d'avertissement<br>
          - Ajout plugin ArcGIS
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Changelog'
}
</script>

<style scoped>
  .content {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
</style>
