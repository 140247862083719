const state = {
  isSendObservationInProgress: false,
}

const getters = {
  isSendObservationInProgress (state) {
    return state.isSendObservationInProgress
  }
}
const actions = {}

const mutations = {
  isSendObservationInProgress (state, value) {
    state.isSendObservationInProgress = value
  }
}

export default {
  state: state,
  getters,
  actions,
  mutations: mutations
}
