import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue from 'bootstrap-vue'
import BootstrapVueIcons from 'bootstrap-vue-icons'

import VCalendar from 'v-calendar'
import Multiselect from 'vue-multiselect'

import i18n from './i18n'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VCalendar)
Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
