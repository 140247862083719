<template>
    <div>
      <div id="nav">
      <b-navbar toggleable="sm" variant="faded" type="light">
        <b-navbar-brand>
          <a v-if="this.$i18n.locale == 'en'" href="https://navigatingwhales.ca/en/home/"
            target="_blank">
          <img :src="`../assets/images/general/logo-fr.png`" class="d-inline-block align-top" alt="ROMM logo" id="logo">
          </a>
          <a v-if="this.$i18n.locale == 'fr'" href="https://navigationbaleines.ca/fr/accueil/"
            target="_blank">
          <img :src="`../assets/images/general/logo-fr.png`" class="d-inline-block align-top" alt="ROMM logo" id="logo">
          </a>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item class="nav-item-font" href="https://observation.navigationbaleines.ca/" v-if="$i18n.locale === 'fr'">{{ $t('outilsaisie-data') }}</b-nav-item>
            <b-nav-item class="nav-item-font" href="https://navigationbaleines.ca/fr/signaler-une-baleine-en-difficulte/" v-if="$i18n.locale === 'fr'">{{ $t('Report-whale') }}</b-nav-item>
            <b-nav-item class="nav-item-font" href="https://observation.navigatingwhales.ca/" v-if="$i18n.locale === 'en'">{{ $t('outilsaisie-data') }}</b-nav-item>
            <b-nav-item class="nav-item-font" href="https://navigatingwhales.ca/en/reporting-a-marine-mammal-in-difficulty/" v-if="$i18n.locale === 'en'">{{ $t('Report-whale') }}</b-nav-item>
            <b-nav-item class="nav-item-font" @click="switchLocale">{{ $i18n.t('action_locale') }}</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <router-link to="/"></router-link>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Header',
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "fr") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "fr";
      }
    },
  },
}
</script>

<style scoped>
#nav {
  flex-shrink: 0;
  background-color: #0A1D31
}

#logo {
  height: 55px;
}

.navbar-nav > .nav-item > .nav-link {
  color: #DCEDE9!important;
  text-transform: uppercase;
}

.navbar-toggler {
  background-color: #DCEDE9!important;
}

.nav-link {
  display: inline-block!important;
}

.btn, .nav-item-font {
  font-family: 'geomanistmedium'!important;
  text-transform: uppercase;
}
.hide {
  display: none;
}
</style>
<style>
.navbar-nav {
  width: 100%;
}

#dropdownUser {
  margin-left: auto;
}

#dropdownUser a {
  color: #DCEDE9;
}

#dropdownUser ul {
  background-color: #0A1D31;
  border: 1px solid #DCEDE9;
}

#dropdownUserNameItem {
  font-weight: bold;
}

#dropdownUserEmailItem {
  font-size: 0.8375rem;
  border-bottom: 1px solid rgb(220,237,233, 0.5);
  padding-bottom: 0.5rem;
}

#dropdownLogoutItem:hover {
  background-color: #DCEDE9!important;
  color: #0A1D31!important;
}

@media screen and (max-width: 576px) {
  #dropdownUser {
    margin-left: 0;
  }
}
</style>
