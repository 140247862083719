import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translationsEn from './i18n/en.json'
import translationsFr from './i18n/fr.json'

Vue.use(VueI18n)

const languages = ["fr", "en"];

export default new VueI18n({
  locale: navigator.language && languages.includes(navigator.language.substring(0, 2)) ? navigator.language.substring(0, 2) : languages[0],
  messages: { 'en': translationsEn, 'fr': translationsFr }
})

