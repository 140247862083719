<template>
  <div>
    <footer class="footer">
      <div class="links-container">
        <div class="first-column">
          <a href="https://navigationbaleines.ca/fr/accueil/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("home")}}</a>
          <a href="https://navigationbaleines.ca/fr/a-propos/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("about")}}</a>
          <a href="https://navigationbaleines.ca/fr/contact/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("contact")}}</a>
          <a href="https://www.visualisation.navigationbaleines.ca/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("viz-data")}}</a>
          <a href="https://navigatingwhales.ca/en/home/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("home")}}</a>
          <a href="https://navigatingwhales.ca/en/about/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("about")}}</a>
          <a href="https://navigatingwhales.ca/en/contact-2/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("contact")}}</a>
          <a href="https://observation.navigatingwhales.ca/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("viz-data")}}</a>
        </div>
        <div class="second-column">
          <a href="https://navigationbaleines.ca/fr/portail-plaisanciers/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("boaters-portal")}}</a>
          <a href="https://navigationbaleines.ca/fr/portail-armateurs/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("ship-operator")}}</a>
          <a href="https://navigationbaleines.ca/fr/portail-pecheurs/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("fishers-operator")}}</a>
          <a href="https://navigationbaleines.ca/fr/portail-excursionnistes/" class="toUppercase" v-if="$i18n.locale === 'fr'">{{$t("whale-watchers-operator")}}</a>
          <a href="https://navigatingwhales.ca/en/boaters-portal/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("boaters-portal")}}</a>
          <a href="https://navigatingwhales.ca/en/shipowners-portal/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("ship-operator")}}</a>
          <a href="https://navigatingwhales.ca/en/fishers-portal/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("fishers-operator")}}</a>
          <a href="https://navigationbaleines.ca/en/whalewatchers-portal/" class="toUppercase" v-if="$i18n.locale === 'en'">{{$t("whale-watchers-operator")}}</a>
        </div>
      </div>
      <div class="partner-container pt-3">
        <div class="logos-partenaires">
          <a href="https://www.dfo-mpo.gc.ca/index-fra.htm" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'fr'">
            <img :src="`../assets/images/general/canada_logo.png`">
          </a>
          <a href="https://ogsl.ca/fr/accueil/" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'fr'">
            <img :src="`../assets/images/general/ogsl_logo_fr.png`">
          </a>
          <a href="http://romm.ca/" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'fr'">
            <img :src="`../assets/images/general/romm_logo.png`">
          </a>
          <a href="http://www.wwf.ca/fr/" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'fr'">
            <img :src="`../assets/images/general/WWF_logo.png`">
          </a>
          <a href="https://www.dfo-mpo.gc.ca/index-eng.html" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'en'">
            <img :src="`../assets/images/general/canada_logo.png`">
          </a>
          <a href="https://ogsl.ca/en/home-slgo/" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'en'">
            <img :src="`../assets/images/general/ogsl_logo_fr.png`">
          </a>
          <a href="http://romm.ca/en/" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'en'">
            <img :src="`../assets/images/general/romm_logo.png`">
          </a>
          <a href="https://wwf.ca/" target="_blank" rel="noopener noreferrer" v-if="$i18n.locale === 'en'">
            <img :src="`../assets/images/general/WWF_logo.png`">
          </a>
        </div>
        <div v-html="$t('copyrightWWF')" id="copyrightWWF">
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  background-color: #0A1D31;
  flex-shrink: 0;
  padding: 30px 30px 30px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.links-container {
  display:flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.links-container a {
  padding: 1px 0px 10px 0px;
  color: #dcede9!important;
  font-family: 'geomanistmedium';
  font-size: 12pt!important;
}

.links-container a:link {
  text-decoration: none;
}

.first-column {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.second-column {
   display: flex;
   flex-direction: column;
 }

.logos-partenaires a img {
  padding: 0 10px;
  vertical-align: bottom;
}

#copyrightWWF {
  color: white;
  font-size: 10px;
  text-align: right;
  padding: 5px 10px 0px 10px;
}

@media screen and (max-width: 370px) {
  .first-column, .second-column, .partner-container {
    text-align: center;
  }

  .partner-container a img {
    padding: 5px !important;
  }
}

@media screen and (max-width: 785px) {
  .partner-container a img {
    padding: 10px !important;
  }

  .partner-container,
  #copyrightWWF {
    text-align: center;
  }
}
</style>
